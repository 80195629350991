import React, { useEffect, useRef } from "react";
import { IMAGES } from "../images/images";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../common/Loader";
import AOS from "aos";
import { Link } from "react-router-dom";

const Home = () => {
  // LOGICAL VARIABLES
  useEffect(() => {
    AOS.init({
      // ... your other initialisation options here ...
      once: true,
    });
  }, []);

  const [email, setEmail] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [hover1, sethover1] = React.useState(false);
  const [hover2, sethover2] = React.useState(false);
  const [hover3, sethover3] = React.useState(false);
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const SendMail = async () => {
    if (!validateEmail(email)) {
      toast.error("S'il vous plaît, mettez une adresse email valide.");
      return;
    }

    try {
      setLoading(true);
      await fetch("https://omni-cave.com:4231/api/v1/auth/mail-send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.message == "Email sent successfully") {
            setLoading(false);
            setEmail("");
            toast.success(
              `Vous vous êtes inscrit avec succès au lancement de notre application. Nous vous informerons lorsqu'elle sera prête à être téléchargée. Nous vous remercions pour l'attention que vous portez à OmniCave !`
            );
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        });
    } catch (error) {
      setLoading(false);
      toast.error("Failed to send email. Please try again.");
      console.error("Error:", error);
    }
  };

  const navbarToggleRef = useRef(null);
  const navbarCollapseRef = useRef(null);

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });

    // Toggle the collapse state of the navbar
    if (
      navbarToggleRef.current &&
      navbarCollapseRef.current.classList.contains("show")
    ) {
      navbarToggleRef.current.click();
    }
  };

  return (
    <React.Fragment ref={navbarToggleRef}>
      <section
        className="bannerSection"
        style={{ backgroundImage: `url(${IMAGES.BannerImg})` }}
      >
        <Container>
          <Row className="align-items-center">
            <Col md={6} data-aos="fade-left" data-aos-duration="1200">
              <h1>
                Bienvenue sur <br /> <span>OmniCave</span>
              </h1>
              <p>
                OmniCave est votre nouvelle plateforme de vente directe de vin
                entre particuliers. Nous croyons que chaque bouteille a une
                histoire à raconter et mérite d'être partagée directement avec
                ceux qui sauront l'apprécier dans un espace convivial et
                sécurisé.
              </p>
              {/* <div className="appComBox">
                <p>Application bientôt disponible</p>
                <Button onClick={() => scrollToSection("registeryourself")}>
                  S’enregistrer
                </Button>
              </div> */}
              <div className="socialLogin">
                <Link onClick={() => scrollToSection("registeryourself")}>
                  <Image src={IMAGES.Google_Img} alt="" />
                </Link>
                <Link onClick={() => scrollToSection("registeryourself")}>
                  <Image src={IMAGES.AppStore_Img} alt="" />
                </Link>
              </div>
            </Col>
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <Image src={IMAGES.Bannerimg_Img} className="ban_img" alt="" />
            </div>
          </Row>
        </Container>
      </section>

      <section
        className="registerSection"
        style={{ backgroundImage: `url(${IMAGES.Register_Img})` }}
        id="registeryourself"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <h2 data-aos="fade-up" data-aos-duration="1000">
                Enregistrez-vous
              </h2>
              <p data-aos="fade-up" data-aos-duration="1500">
                Inscrivez-vous dès maintenant pour suivre les avancées de notre
                application, connaître la date de sortie et profiter d'un mois
                d'essai gratuit dès son lancement !
              </p>
              <div
                className="sendbTn"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <Form.Control
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder="Adresse mails"
                  onChange={(text) => setEmail(text.target.value)}
                  value={email}
                />
                <Button onClick={() => SendMail()}>
                  {loading ? (
                    <Loader color={"white"} style={{ marginLeft: -35 }} />
                  ) : (
                    <Icon icon="ion:paper-plane-outline" />
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="howitwork" className="commonpad howitwork">
        <Container>
          <Row>
            <Col lg={12} md={12} className="text-center">
              <h3 className="h3" data-aos="fade-up" data-aos-duration="1000">
                Comment ça marche
              </h3>
              <h2 data-aos="fade-up" data-aos-duration="1500">
                Comment l'utiliser
              </h2>
              <p data-aos="fade-up" data-aos-duration="2000">
                Laissez-vous portez par le fonctionnement intuitif OmniCave.
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={4} md={6}>
              <div
                className="howitwork__body"
                onMouseEnter={() => sethover1(true)}
                onMouseLeave={() => sethover1(false)}
              >
                <div
                  className="numberBox"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  <h4>01</h4>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500">
                  <Icon
                    icon="ic:baseline-search"
                    style={{ color: hover1 ? "white" : "#a51d43" }}
                    width="60px"
                    height="60px"
                  />
                </div>
                <h3 data-aos="fade-up" data-aos-duration="1500">
                  Rechercher et explorer les vins
                </h3>
                <p data-aos="fade-up" data-aos-anchor-placement="center-center">
                  Notre système de recherche avancé vous permet de trouver
                  facilement la bouteille que vous désirez.
                </p>
                <Image className="arrowPos" src={IMAGES.Arrow_SVG} />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div
                className="howitwork__body"
                onMouseEnter={() => sethover2(true)}
                onMouseLeave={() => sethover2(false)}
              >
                <div
                  className="numberBox"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  <h4>02</h4>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500">
                  <Icon
                    icon="streamline:payment-10-solid"
                    style={{ color: hover2 ? "white" : "#a51d43" }}
                    width="60px"
                    height="60px"
                  />
                </div>
                <h3 data-aos="fade-up" data-aos-duration="1500">
                  Acheter ou faire une offre
                </h3>
                <p data-aos="fade-up" data-aos-anchor-placement="center-center">
                  Si le prix de vente ne vous convient pas faites une offre au
                  vendeur !
                </p>

                <Image className="arrowPos" src={IMAGES.Arrow_SVG} />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div
                className="howitwork__body"
                onMouseEnter={() => sethover3(true)}
                onMouseLeave={() => sethover3(false)}
              >
                <div
                  className="numberBox"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  <h4>03</h4>
                </div>
                <div data-aos="fade-up" data-aos-duration="1500">
                  <Icon
                    icon="carbon:delivery"
                    style={{ color: hover3 ? "white" : "#a51d43" }}
                    width="60px"
                    height="60px"
                  />
                </div>
                <h3 data-aos="fade-up" data-aos-duration="1500">
                  Recevez votre commande
                </h3>

                <p data-aos="fade-up" data-aos-anchor-placement="center-center">
                  Livraison rapide et fiable dans toute la France, pour que vous
                  puissiez profiter de vos achats sans attendre.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className="commonpad aboutUs"
        id="aboutus"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <Container>
          <Row className="align-items-center">
            <Col
              md={6}
              data-aos="fade-left"
              data-aos-duration="1200"
              className="text-center"
            >
              <Image src={IMAGES.About_Img} alt="" className="img-fluid cImg" />
            </Col>
            <Col md={6}>
              <h3 className="h3" data-aos="fade-up" data-aos-duration="1000">
                À PROPOS DE NOUS
              </h3>
              <h2 data-aos="fade-up" data-aos-duration="1300">
                Embarquez pour un voyage autour du vin avec OmniCave
              </h2>
              <div data-aos="fade-up" data-aos-duration="1600">
                <p>
                  OmniCave est une application innovante conçue pour
                  révolutionner la manière dont les amateurs de vin échangent
                  leurs bouteilles. Notre plateforme facilite la vente et
                  l’achat de vins directement entre particuliers, sans passer
                  par des intermédiaires. Que vous soyez un collectionneur
                  passionné, un fin connaisseur, ou simplement un amateur en
                  quête de découvertes, OmniCave vous offre un espace sécurisé
                  et convivial pour enrichir votre cave ou partager vos trésors
                  œnologiques.{" "}
                </p>
                {/* <h6 className="aboutusHeading">Notre mission :</h6> */}
                <p>
                  Notre mission est de rendre le commerce du vin plus
                  transparent et accessible, tout en créant une véritable
                  communauté d’amateurs autour de cette passion commune.{" "}
                </p>
                {/* <p>
                  Faciliter la découverte et la vente de vins d'exception entre
                  particuliers. Nous créons une communauté où l'échange se fait
                  en toute confiance.{" "}
                </p> */}
                {/* <h6 className="aboutusHeading">Ce que nous offrons :</h6>
                <p>
                  {" "}
                  Sécurité totale : Transactions protégées jusqu'à la réception
                  de votre bouteille.. <br /> <br /> Vins rares : Accédez à des
                  sélections uniques et introuvables ailleurs. <br />
                  <br /> Communauté passionnée : Partagez vos coups de cœur et
                  suivez vos vendeurs favoris.
                </p>
                <h6 className="aboutusHeading"> Pourquoi choisir OmniCave ?</h6>
                <p>
                  Transparence et confiance : Vendeurs et acheteurs vérifiés.{" "}
                  <br /> <br /> Simplicité : Interface intuitive pour une
                  expérience fluide. <br /> <br />
                  Support :Une équipe dédiée pour vous accompagner.
                </p> */}
              </div>
              {/* <p data-aos="fade-up" data-aos-duration="2000" className="mb-4">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </p> */}

              {/* <Link className="btn btn-primary">Read More</Link> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section id="downloadapp" className="commonpad downloadApp">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h3 className="h3" data-aos="fade-up" data-aos-duration="1000">
                Application mobile
              </h3>
              {/* <h2>Download Our Mobile App</h2> */}
              <h2 data-aos="fade-up" data-aos-duration="1000">
                L'application Omnicave arrive bientôt
              </h2>
              <p data-aos="fade-up" data-aos-duration="1000">
                Notre application sera bientôt disponible ! Restez informé pour
                être parmi les premiers à l'essayer dès son lancement.
              </p>

              <div className="socialLogin">
                <Link onClick={() => scrollToSection("registeryourself")}>
                  <Image src={IMAGES.Google_Img} alt="" />
                </Link>
                <Link onClick={() => scrollToSection("registeryourself")}>
                  <Image src={IMAGES.AppStore_Img} alt="" />
                </Link>
              </div>
              {/* <div
                className="appComBox"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <p>Application bientôt disponible</p>
                <Button onClick={() => scrollToSection("registeryourself")}>
                  S’enregistrer
                </Button>
              </div> */}
            </Col>

            <Col md={6}>
              <Image
                src={IMAGES.AppDownload_Img}
                alt=""
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Home;
